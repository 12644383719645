window.addEventListener('load', () => {
    // Set width to "auto" for .main-menu > .mega-menu > .sub-menu
    var mainMenuSubMenu = document.querySelector('.main-menu > .mega-menu > .sub-menu');
    mainMenuSubMenu.style.width = 'auto';

    // Rest of the logic for mouseover and mouseout events
    var productsMenuItem = document.getElementById("menu-item-316");
    productsMenuItem.addEventListener("mouseover", function () {
        var subMenu = this.querySelector(".sub-menu");
        subMenu.style.width = "1075px";
    });
    productsMenuItem.addEventListener("mouseout", function () {
        var subMenu = this.querySelector(".sub-menu");
        subMenu.style.width = "auto";
    });

    // When mouseover on Resources menu
    var resourcesMenuItem = document.getElementById("menu-item-333");
    resourcesMenuItem.addEventListener("mouseover", function () {
        var subMenu = this.querySelector(".sub-menu");
        subMenu.style.width = "950px";
    });
    resourcesMenuItem.addEventListener("mouseout", function () {
        var subMenu = this.querySelector(".sub-menu");
        subMenu.style.width = "auto";
    });
});